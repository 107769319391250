@import "../global/style.scss";

.pageLayout {
  width: 55%;
  margin: auto;
  padding: 2rem;
  //   position: relative;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 100%;
    margin: auto;
  }
}

.tabBarStyle {
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid $color-border;
}

.tabStyle {
  font-size: 1.4rem !important;
  text-transform: capitalize !important;
}

.coinContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.coinDetails {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.coinName {
  font-weight: 600;
  color: $color-font-dull;
}

.coinSymbol,
.coinPrice {
  font-weight: 600;
}

.coinPrice,
.priceChange {
  text-align: end;
}

.priceChange {
  font-size: 1.2rem;
  font-weight: 500;
}

.changePeriod {
  color: $color-font-dull;
}

.presaleMsg {
  text-align: end;
  color: $color-primary;
  font-size: 1.2rem;
  font-weight: 500;
}
