@import "../global/style.scss";

.headerContainer {
  max-height: 8rem;
  padding: 1rem 2rem;
  background-color: $color-background-primary;
  border-bottom: 0.1rem solid $color-border;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header {
  height: 6rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;

  width: 80%;
  margin: auto;

  @media only screen and (max-width: $bp-1280) {
    width: 90%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 100%;
    margin: auto;
  }
}

.logoContainer {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  width: 15rem;
}

.googlePlayImg {
  width: 10rem;

  @media only screen and (max-width: $bp-480) {
    display: none;
  }
}

.navContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $bp-840) {
    display: none;
  }
}

.navList {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  font-family: $font-navigation;
}

.navItem {
  display: block;
  font-family: $font-navigation;
  padding: 0.4rem 1.5rem;
  color: $color-font-dull;
  border-radius: 0.5rem;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    $color-primary 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  .navItemContainer {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .navIcons {
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    fill: $color-font-dull;
  }

  &:hover {
    color: $color-font-secondary;
    background-position: 100%;

    .navIcons {
      fill: $color-font-secondary;
    }
  }
}

.activeNavItem {
  color: $color-font-secondary;
  background-color: $color-background-secondary;
  border-radius: 0.5rem;

  .navIcons {
    fill: $color-font-secondary;
  }
}

.profileContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: $bp-840) {
    display: none;
  }
}

.supportIcon {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
}

.userProfileContainer {
  width: 3.2rem;
  height: 3.2rem;
  background-color: $color-background-dull;
  border-radius: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.userProfileText {
  font-size: 1.8rem;
  font-weight: 800;
  color: $color-font-primary;
}

.userIcon {
  display: block;
  color: $color-font-primary;
  font-size: 2.2rem;
  cursor: pointer;
}

.navContainerMobile {
  display: none;

  @media only screen and (max-width: $bp-840) {
    display: block;
  }
}

.menuIconContainer {
  padding: 0.7rem 1.5rem;
  background-color: $color-background-secondary;
  border-radius: 0.5rem;
}

.menuIcon {
  display: block;
  color: $color-font-secondary;
  font-size: 2rem;
  cursor: pointer;
}

.mNav {
  position: relative;
}

.mNavList {
  width: 100vw;
  list-style-type: none;
  font-family: $font-navigation;
  position: fixed;
  left: 0;
  margin-top: 2.4rem;
  background-color: $color-background-primary;
  border-left: 0.1rem solid $color-border;
  border-right: 0.1rem solid $color-border;
  z-index: 100;
}

.mNavListItem {
  width: 100%;
  text-align: center;
}

.mNavItem {
  width: 100%;
  display: block;
  padding: 1rem !important;
  color: $color-font-dull;
  border-bottom: 0.1rem solid $color-border;

  .navItemContainer {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .navIcons {
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    fill: $color-font-dull;
  }

  &:hover {
    color: $color-font-secondary;
    background-color: $color-background-secondary;

    .navIcons {
      fill: $color-font-secondary;
    }
  }
}

.mActiveNavItem {
  display: block;
  color: $color-font-secondary;
  background-color: $color-background-secondary;

  .navIcons {
    fill: $color-font-secondary;
  }
}
