@import "../global/style.scss";

.pageLayout,
.buttonContainer {
  width: 55%;
  margin: auto;
  padding: 2rem;
  //   position: relative;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.backButton {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.img_wishlist_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.coinDetailsContainer {
  margin-bottom: 2rem;
}

.coinImg {
  width: 5rem;
  height: 5rem;
}

.coinName {
  color: $color-font-dull;
  font-weight: 500;
}

.coinValue {
  font-size: 3.6rem;
  font-weight: 700;
}

.offerValue {
  font-size: 3.2rem;
  font-weight: 500;
  text-decoration: line-through;
}

.priceChange {
  font-size: 1.4rem;
  font-weight: 500;
}

.changePeriod,
.coinPropertyTitle,
.coin_desc {
  color: $color-font-dull;
}

.coin_desc {
  margin-bottom: 10rem;
}

.presaleMsg {
  color: $color-primary;
  font-size: 1.4rem;
  font-weight: 500;
}

.graph {
  height: 30rem;
  background-color: beige;
  margin-bottom: 2rem;
}

.countdownContainer {
  padding: 2rem;
  background-color: $color-background-dark;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.countdownMessage {
  color: $color-font-secondary;
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}

.countdownHighlight {
  font-weight: 700;
}

.holding_cont {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
  padding: 2rem;
  border: 0.1rem solid $color-border;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
  }
}

.coinHoldingProperties {
  flex: 1;

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.coinHoldingProperty {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
}

.coinInfoContainer {
  margin-bottom: 2rem;
}

.aboutCoin_heading {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.coinProperties {
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
  }
}

.coinProperty {
  flex: 1;
  text-align: center;
  padding: 2rem;
  border: 0.1rem solid $color-border;

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.coinPropertyValue {
  font-weight: 700;
}

.buy_sell_cont {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $color-background-primary;
  border-top: 0.1rem solid $color-border;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
  }
}

.buttonContainer {
  margin-bottom: 0rem;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
  }
}

.formContainer {
  width: 35%;
  margin: auto;
  //   position: relative;

  @media only screen and (max-width: $bp-1280) {
    width: 45%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-600) {
    width: 75%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-480) {
    width: 100%;
    margin: auto;
  }
}

.formContainer {
  padding: 2rem;

  @media only screen and (max-width: $bp-600) {
    padding: 2rem 1rem;
  }
}

.drawerHeading {
  text-align: center;
  // margin-bottom: 2rem;
}

.formInputContainer {
  margin-bottom: 1.5rem;
}

.formInputTitleContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.formInputTitle {
  font-weight: 700;
}

.formRadioBox {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}
