@import "../global/style.scss";

.pageLayout {
  margin: auto;
  width: 50%;
  padding: 1rem 0;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.pageHeading {
  font-size: 2rem;
  text-align: center;
}

// .orderDetailsContainer {
// }

.orderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.coinDetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.coinSymbol {
  font-weight: 600;
}

.successOrderStatus {
  padding: 0 1rem;
  background-color: rgba(9, 140, 38, 0.1);
  border: 0.1rem solid green;
  border-radius: 2.5rem;
}

.failedOrderStatus {
  padding: 0 1rem;
  background-color: rgba(140, 9, 9, 0.1);
  border: 0.1rem solid red;
  border-radius: 2.5rem;
}

.processingOrderStatus {
  padding: 0 1rem;
  background-color: rgba(9, 16, 140, 0.1);
  border: 0.1rem solid $color-primary;
  border-radius: 2.5rem;
}

.orderProperty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.orderPropertyTitle {
  font-weight: 500;
  color: $color-font-dull;

  @media only screen and (max-width: $bp-600) {
    font-size: 1.4rem;
  }
}

.orderPropertyValue {
  text-align: end;
  font-weight: 500;
  word-break: break-all;

  @media only screen and (max-width: $bp-600) {
    font-size: 1.4rem;
  }
}

.orderPropertyValueContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.copyIcon {
  font-size: 2.4rem;
  color: $color-primary;
  cursor: pointer;
}

.helpLinkContainer {
  text-align: center;
  margin-bottom: 2rem;
}

.linkText {
  font-weight: 600;

  @media only screen and (max-width: $bp-960) {
    font-size: 1.4rem;
  }
}
