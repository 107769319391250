@import "../global/style.scss";

.pageLayout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: $bp-600) {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}

.otpVerificationLayout {
  display: flex;
  justify-content: center;
}

.container {
  width: 30vw;
  min-height: 40rem;
  height: fit-content;
  padding: 2rem;
  background-color: $color-background-dull;
  border-radius: 1rem;

  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $bp-1280) {
    width: 40vw;
  }

  @media only screen and (max-width: $bp-960) {
    width: 50vw;
  }

  // @media only screen and (max-width: $bp-840) {
  //   width: 90vw;
  // }

  @media only screen and (max-width: $bp-600) {
    width: 70vw;
    height: fit-content;
    margin: 5rem auto 5rem auto;

    flex-direction: column;
  }

  @media only screen and (max-width: $bp-480) {
    width: 95vw;
  }
}

.changePasswordFormHolder {
  width: 100%;
}

.forgetPasswordHighlightedTitle {
  color: $color-button-primary;
}

.imageContainer {
  flex: 1;
}

.formContainer {
  flex: 1;
  //   max-width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formInputContainer {
  margin-bottom: 1.5rem;
}

.forgetPasswordInputSubcontainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.multiInputContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.formInputTitleContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.formInputTitle {
  font-weight: 700;
}

.otpReceivedGif {
  width: 100%;
}

.message {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.requestAgain {
  color: $color-primary;
  cursor: pointer;
}
