@import "../global/style.scss";

.socialMedia_cont {
    display: flex;
    gap: 2rem;
}

.socailMediabtn {
  border: none;
}
