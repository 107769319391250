@import "../global/style.scss";

.container {
  margin: auto;
  width: 55%;
  padding: 1rem 0;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 100%;
    margin: auto;
  }
}

.fiatContainer,
.cryptoContainer {
  padding: 1rem;
}

.fiatMessageContainer {
  width: 70%;
  margin: auto;

  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  color: $color-font-secondary;
  background-color: $color-background-dark;
  border-radius: 3rem;
  padding: 1rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: $bp-600) {
    width: 100%;
    padding: 0.5rem;
  }
}

.messaegeIcon {
  font-size: 2rem;
  margin-bottom: -0.2rem;

  @media only screen and (max-width: $bp-600) {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
}

.fiatMessage {
  @media only screen and (max-width: $bp-600) {
    font-size: 1.2rem;
  }
}

.fiatCard {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.fiatInfoContainer {
  flex: 1;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.currenciesIcon {
  width: 3.6rem;
}

.fiatTitle {
  color: $color-font-dull;
  font-size: 1.6rem;
  font-weight: 600;

  @media only screen and (max-width: $bp-480) {
    font-size: 1.4rem;
  }
}

.fiatCurrency {
  font-size: 1.6rem;
  font-weight: 600;
  color: $color-font-primary;

  @media only screen and (max-width: $bp-480) {
    font-size: 1.4rem;
  }
}

.paymentMethodContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: $bp-600) {
    gap: 0.5rem;
  }
}

.paymentMethodLogo {
  width: 5rem;

  @media only screen and (max-width: $bp-600) {
    width: 4rem;
  }

  @media only screen and (max-width: $bp-480) {
    width: 3.2rem;
  }
}

// .fiatActionContainer {
//   //
// }

.actionIcon {
  font-size: 2.4rem;
  color: $color-font-primary;

  @media only screen and (max-width: $bp-600) {
    font-size: 2rem;
  }
}

.coinHeading {
  font-weight: 500;
  margin-bottom: 2rem;
}

.coinContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.coinDetailContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.coinName {
  color: $color-font-dull;
  font-size: 1.6rem;
  font-weight: 600;
}

.coinSymbol {
  font-size: 1.6rem;
  font-weight: 600;
  color: $color-font-primary;
}
