@import "../global//style.scss";

.pageLayout {
  margin: auto;
  width: 50%;
  padding: 1rem 0;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.coinHeading {
  font-weight: 600;
  margin-bottom: 2rem;
}

.coinContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

.coinDetailContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.coinName {
  color: $color-font-primary;
  font-size: 1.6rem;
  font-weight: 600;
}

.holdings {
  color: $color-font-dull;
  font-size: 1.6rem;
  font-weight: 600;
}

.withdrawHeading {
  font-size: 2rem;
  font-weight: 600;
  margin: 2rem auto;
}

.formPropertyContainer {
  margin-bottom: 1rem;
}

.propertyTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.propertyDetails {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.propertyDetailTitle {
  font-size: 1.4rem;
  font-weight: 500;
  color: $color-font-dull;
}

.propertyDetailValue {
  font-size: 1.4rem;
  font-weight: 500;
  color: $color-font-primary;
}

.withdrawDetails {
  padding: 2rem;
  background-color: $color-background-dull;
  border-radius: 1rem;
}

.withdrawDetailTitle {
  font-size: 1.4rem;
  font-weight: 600;
  color: $color-primary;
}

.withdrawDetailMessage {
  font-weight: 500;
}

.warningContainer {
  padding: 2rem;
  background-color: $color-background-primary;
  border-radius: 1rem;
}

.warningMessage {
  font-weight: 500;
}
