@import "../global/style.scss";

.pageLayout {
  margin: auto;
  width: 50%;
  padding: 1rem 0;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.walletBackground {
  background-color: $color-component;
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: $bp-480) {
    padding: 1rem;
  }
}

.walletContainer {
  background-color: $color-background-primary;
  border: 0.1rem solid white;
  border-radius: 2rem;
  padding: 2.5rem;

  @media only screen and (max-width: $bp-600) {
    padding: 1.5rem;
  }
}

.walletHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nomoexLogo {
  width: 12rem;

  @media only screen and (max-width: $bp-600) {
    width: 10rem;
  }
}

.walletTitle {
  font-size: 2.2rem;
  // color: $color-font-secondary;
}

.refreshButton {
  width: 2.4rem;
  height: 2.4rem;
  // color: $color-font-secondary;
  cursor: pointer;
}

.totalHoldingContainer {
  margin-bottom: 2rem;
}

.totalHolding {
  font-size: 2.4rem;

  @media only screen and (max-width: $bp-600) {
    font-size: 2rem;
  }
}

//second component

.investmentContainer {
  padding: 1rem 2rem;
  background-color: $color-background-dull;
  border-radius: 1rem;

  @media only screen and (max-width: $bp-600) {
    padding: 1rem;
  }
}

.coinsHeading {
  color: $color-font-dull;
  font-weight: 600;
  margin-bottom: 1rem;
}

.coinContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.coinDetails {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.coinName {
  font-weight: 600;
  color: $color-font-dull;
}

.coinHoldings {
  font-weight: 600;
  color: $color-font-primary;
}

.coinPrice {
  font-weight: 600;
  text-align: end;
  color: $color-font-primary;
}
