@import "../global/style.scss";

.pageLayout {
  margin: auto;
  width: 55%;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.card_navigatebtn {
  border: none;
  width: 100%;
}

.orderCard {
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: $color-background-dull;
  border-radius: 1rem;
}

.orderHeader {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.orderStatusContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: $bp-480) {
    flex-direction: column;
    gap: 0.1rem;
    justify-content: center;
    align-items: flex-start;
  }
}

.successOrderStatus {
  padding: 0 1rem;
  background-color: rgba(9, 140, 38, 0.1);
  border: 0.1rem solid green;
  border-radius: 2.5rem;
}

.failedOrderStatus {
  padding: 0 1rem;
  background-color: rgba(140, 9, 9, 0.1);
  border: 0.1rem solid red;
  border-radius: 2.5rem;
}

.coinName {
  font-weight: 700;
  color: $color-font-primary;
}

.orderDate,
.transactionDate {
  font-weight: 500;
  font-size: 1.2rem;
  color: $color-font-dull;
}

.orderDetailsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.detailTitle,
.detailValue,
.transactionTitle {
  font-weight: 500;

  @media only screen and (max-width: $bp-480) {
    font-size: 1.4rem;
  }
}

.detailTitle {
  color: $color-font-dull;
}

.detailValue,
.transactionTitle {
  color: $color-font-primary;
}

.paginationContainer {
  margin: 2rem auto;

  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.previousButton,
.nextButton {
  flex: 1;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-font-secondary;
  background-color: $color-background-secondary;

  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $color-font-primary !important;
    background-color: $color-secondary !important;
  }
}

//// statement tab

.transactionCard {
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: $color-background-dull;
  border-radius: 1rem;

  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.transactionDetails {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.transactionAmount {
  font-weight: 500;
  color: $color-font-primary;
  text-align: end;
}

.transactionStatus {
  color: $color-primary;
}
