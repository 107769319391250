@import "../global/style.scss";

.pageLayout {
  margin: auto;
  width: 50%;
  padding: 1rem 0;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.depositMessage {
  margin: 2rem auto;
  text-align: center;
  font-weight: 500;
}

.coinDetailsContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.coinDetails {
  flex: 1;
}

.coinDetail {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.detailTitle {
  font-weight: 600;
  margin-bottom: 1rem;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.coinName {
  color: $color-font-dull;
  font-size: 1.6rem;
  font-weight: 600;
}

.chainDetails {
  flex: 1;
}

.qrCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding: 2rem;
  margin: auto;

  background-color: $color-primary;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.addressDetailsContainer {
  padding: 2rem;
  background-color: $color-background-dull;
  border-radius: 1rem;
}

.detailHeading {
  font-size: 1.8rem;
  font-weight: 600;
  color: $color-primary;
  margin-bottom: 1rem;
}

.depositAddressContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.depositAddress {
  font-size: 1.8rem;
  font-weight: 600;
  word-break: break-all;
}

.copyIcon {
  font-size: 2.4rem;
  color: $color-primary;
  cursor: pointer;
}

.warningContainer {
  padding: 2rem;
  background-color: $color-background-primary;
  border-radius: 1rem;
}

.warningMessage {
  font-weight: 500;
}
