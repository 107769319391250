// ANT DESIGN
@import "../global/style.scss";

.ant-tabs-nav {
  width: 100% !important;
}

.ant-tabs-tab {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: $color-button-primary;
}

.ant-tabs-card .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.flip-countdown-title {
  color: white;
  font-size: 1.8rem !important;
}

.ant-card-body {
  padding: 10px !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 2rem !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}
