@import "../global/style.scss";

.layout {
  margin: auto;
  width: 50%;
  padding: 1rem 0;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 95%;
    margin: auto;
  }
}

.container {
  margin: 1rem;
  background-color: $color-background-dull;
  padding: 2rem 2rem 8rem;
  border: 0.2rem solid $color-border;
  border-radius: 2rem;

  @media only screen and (max-width: $bp-480) {
    padding: 1rem 1rem 8rem;
  }
}

.profile_cont {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 3rem;

  @media only screen and (max-width: $bp-960) {
    gap: 1rem;
  }
}

.userAvatar {
  width: 12rem;

  @media only screen and (max-width: $bp-600) {
    width: 8rem;
  }
}

.userName {
  font-weight: 600;

  @media only screen and (max-width: $bp-600) {
    font-size: 1.6rem;
  }
}

.userEmail {
  color: $color-font-dull;

  @media only screen and (max-width: $bp-600) {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: $bp-480) {
    word-break: break-all;
  }
}

.copyuid_cont {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.uid {
  @media only screen and (max-width: $bp-600) {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: $bp-480) {
    word-break: break-all;
  }
}

.copyIcon {
  font-size: 2.4rem;
  color: $color-primary;
  cursor: pointer;
}

.moreInfo_card {
  padding: 1rem 0;
  background-color: transparent;
  border: none;
}

.moreInfo_container {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.icon_moreInfo_topic {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.moreinfo_topic {
  font-weight: 600;

  @media only screen and (max-width: $bp-960) {
    font-size: 1.6rem;
  }
}

.socialNetwork_cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  margin-top: -7rem;
  margin-bottom: 2rem;
}

.socialNetwork_heading {
  font-weight: 600;
  color: $color-font-dull;
}

.logoutbtn_cont {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.logout_btn {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 2rem !important;
}
