@import "../global/style.scss";

.container {
  margin: auto;
  width: 55%;
  padding: 1rem;

  @media only screen and (max-width: $bp-1440) {
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-960) {
    width: 100%;
    margin: auto;
  }
}

.enterAmountHeading {
  text-align: center;
  margin-bottom: 2rem;
  color: $color-font-dull;
}

.inputContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: auto;
  margin-bottom: 5rem;

  @media only screen and (max-width: $bp-1280) {
    width: 60%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 70%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 80%;
  }

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.amountInput {
  text-align: center;
  font-size: 2rem;
}

.usdtPriceContainer {
  padding: 1rem;
  background-color: rgba(9, 16, 140, 0.1);
  border: 0.1rem solid $color-primary;
  border-radius: 1rem 1rem 0 0;
  text-align: center;

  width: 30%;
  margin: auto;

  @media only screen and (max-width: $bp-1280) {
    width: 40%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 50%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 60%;
  }

  @media only screen and (max-width: $bp-480) {
    width: 80%;
  }
}

.allotedUSDTContainer {
  padding: 1rem;
  background-color: $color-background-dark;
  border-radius: 1rem;
  text-align: center;

  width: 50%;
  margin: auto;
  margin-bottom: 5rem;

  @media only screen and (max-width: $bp-1280) {
    width: 60%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 70%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 80%;
  }

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.allotedUSDTNote {
  color: $color-font-secondary;
  margin-bottom: 1rem;
}

.allotedUSDT {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;
  background-color: $color-background-primary;
  border-radius: 1rem;

  width: 70%;
  margin: auto;

  @media only screen and (max-width: $bp-1280) {
    width: 80%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 90%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 100%;
  }
}

.allotedUSDTTitle {
  color: $color-font-dull;
}

.allotedUSDTAmount {
  font-weight: 600;
}

.paymentButtonContainer {
  width: 50%;
  margin: auto;
  text-align: center;

  @media only screen and (max-width: $bp-1280) {
    width: 60%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 70%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 80%;
  }

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.paymentButtonCheckbox {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.payableAmountContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid $color-border;
  padding: 1rem;
  margin-bottom: 2rem;
}

.qrCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding: 2rem;
  margin: auto;

  background-color: $color-primary;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.shareQRButtonContainer {
  margin: auto;
  text-align: center;
  margin-bottom: 1rem;
}

.shareQRButton {
  height: 4rem;
  background-color: rgba(9, 16, 140, 0.1);
  border: 0.1rem solid $color-primary;
  border-radius: 1rem;
}

.separator {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-weight: 700;
  margin-bottom: 1rem;
}

.separator::before,
.separator::after {
  content: "";
  height: 2px;
  background-color: $color-background-dark;
  flex-grow: 1;
}

.paymentMethodContainer {
  width: 50%;
  margin: auto;
  margin-bottom: 5rem;

  @media only screen and (max-width: $bp-1280) {
    width: 60%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 70%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 80%;
  }

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.paymentMethodButton {
  width: 100%;
  height: 5rem;
  background-color: $color-background-dark;

  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.paymentMethodTitle {
  color: $color-font-secondary;
}

.paymentMethodIcons {
  display: flex;
  gap: 0.2rem;
  justify-content: flex-end;
  align-items: center;
}

.paymentMethodLogo {
  height: 4rem;

  @media only screen and (max-width: $bp-600) {
    height: 4rem;
  }

  @media only screen and (max-width: $bp-480) {
    height: 2.4rem;
  }
}

.successScreenContainer {
  width: 50%;
  margin: auto;
  text-align: center;

  @media only screen and (max-width: $bp-1280) {
    width: 60%;
  }

  @media only screen and (max-width: $bp-960) {
    width: 70%;
  }

  @media only screen and (max-width: $bp-600) {
    width: 80%;
  }

  @media only screen and (max-width: $bp-480) {
    width: 100%;
  }
}

.successIcon {
  margin-bottom: 3rem;
}

.successMessages {
  margin-bottom: 3rem;
}
